
export default {
  props: {
    // eslint-disable-next-line vue/require-prop-types
    filePicker: {
      default: false,
    },
  },
  emits: ["scriptLoaded", "gsiScriptLoaded", "error", "picked"],
  data() {
    return {
      picked: {},
      pickerApiLoaded: false,
      gsiScriptLoaded: false,
      selectFolder: false,
      config: process.env.googlePickerConfig,
    };
  },
  mounted() {
    const element = document.getElementById("FileInputDropdown");
    if (!this.filePicker || element.matches(":hover")) {
      this.loadScript();
    }
  },
  methods: {
    loadScript() {
      if (window.gapi && this.pickerApiLoaded) {
        this.scriptLoaded = true;
        this.$emit("scriptLoaded");
      } else {
        const googleScript = document.createElement("script");
        googleScript.onload = () => {
          window.gapi.load("auth2");
          window.gapi.load("picker", () => {
            this.pickerApiLoaded = true;
            this.$emit("scriptLoaded");
          });
        };
        googleScript.setAttribute("src", "https://apis.google.com/js/api.js");
        googleScript.setAttribute("id", "googlepickerjs");
        document.head.appendChild(googleScript);

        const gsiScript = document.createElement("script");
        gsiScript.onload = () => {
          if (window.gapi) {
            window.gapi.load("auth2");
            window.gapi.load("picker", () => {
              this.gsiScriptLoaded = true;
              this.$emit("gsiScriptLoaded");
            }); 
          }
        };
        gsiScript.setAttribute("src", "https://accounts.google.com/gsi/client");
        gsiScript.setAttribute("id", "gsiscript");
        document.head.appendChild(gsiScript);

        window.getGooglePermission = () => this.reqPermission();
      }
    },
    reqPermission() {
      let authResult = undefined;
      const client = google.accounts.oauth2.initTokenClient({
        client_id: this.config.clientId,
        scope: this.config.scope,
        callback: (result) => {
          authResult = result;
        },
      });
      client.requestAccessToken();

      if (authResult && !authResult.error) {
        // eslint-disable-next-line no-undef
        $nuxt.$store.commit("setGoogleTokens", {
          oauthToken: authResult.access_token,
          gtokens: JSON.stringify(authResult),
        });
      } else {
        return console.warn("auth error from frontend", authResult);
      }
    },
    handleAuthResult(authResult) {
      if (authResult && !authResult.error) {
        this.$store.commit("setGoogleTokens", {
          oauthToken: authResult.access_token,
          gtokens: JSON.stringify(authResult),
        });
        if (this.selectFolder) {
          this.createFolderPicker();
        } else {
          this.createPicker();
        }
      } else if (authResult.error === "idpiframe_initialization_failed") {
        this.$emit("error");
        this.$store.commit("setAlert", {
          msg: "Error: Could not load cookies. Are you using incognito mode?",
          sticky: true,
          type: "error",
        });
        console.warn("auth result error from frontend- ", authResult);
      } else {
        this.$emit("error");
        console.warn("auth result error from frontend-- ", authResult);
      }
    },
    handleFileSelect() {
      this.selectFolder = false;
      if (this.$store.state.googleToken.oauthToken) {
        this.createPicker();
      } else if (window.gapi.auth2) {
        const client = google.accounts.oauth2.initTokenClient({
          client_id: this.config.clientId,
          scope: this.config.scope,
          callback: (tokenResponse) => {
            this.handleAuthResult(tokenResponse);
          },
        });
        client.requestAccessToken();
      }
    },
    createPicker() {
      if (this.$store.state.googleToken.oauthToken) {
        // eslint-disable-next-line no-undef
        const picker = new google.picker.PickerBuilder()
          // eslint-disable-next-line no-undef
          .addView(google.picker.ViewId.DOCS)
          .setOAuthToken(this.$store.state.googleToken.oauthToken)
          .setDeveloperKey(this.config.pickerKey)
          .setAppId(this.config.appId)
          // eslint-disable-next-line no-undef
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .setCallback(this.pickerCallback)
          .build();
        picker.setVisible(true);
      }
    },
    pickerCallback(data) {
      localStorage.setItem("GOOGLE_PICKER_ENABLE","true");
      // eslint-disable-next-line no-undef, eqeqeq
      if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
        this.$emit(
          "picked",
          data.docs.map((file) => ({
            name: file.name,
            url: file.id,
            size: file.sizeBytes,
            gtoken: this.$store.state.googleToken.gtokens,
            // id of the parent folder
            gDriveParentFolderId: file.parentId
          }))
        );
      }
    },
    async handleFolderSelect() {
      await this.loadScript();
      const loaded = setInterval(() => {
        if (window.gapi && window.gapi.auth2) {
          clearInterval(loaded);
          this.selectFolder = true;
          if (this.$store.state.googleToken.oauthToken) {
            this.createFolderPicker();
          } else {
            const client = google.accounts.oauth2.initTokenClient({
              client_id: this.config.clientId,
              scope: this.config.scope,
              callback: (tokenResponse) => {
                this.handleAuthResult(tokenResponse);
              },
            });
            client.requestAccessToken();
          }
        }
      }, 100);
    },
    createFolderPicker() {
      if (this.$store.state.googleToken.oauthToken) {
        this.$emit("picked", {
          folder: "root",
          token: this.$store.state.googleToken.gtokens,
        });
      }
    },
    folderPickerCallback(data) {
      // eslint-disable-next-line no-undef, eqeqeq
      if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
        if (data.docs && data.docs.length) {
          this.$emit("picked", {
            folder: data.docs[0].id,
            token: this.$store.state.googleToken.gtokens,
          });
        }
      }
    },
    async open() {
      await this.loadScript();
      const loaded = setInterval(() => {
        if (window.gapi) {
          this.handleFileSelect();
          clearInterval(loaded);
        }
      }, 100);
    },
  },
};
